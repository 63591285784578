.popup {
  display: flex;
  visibility: hidden;
  opacity: 0;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  transition: visibility 0.5s, opacity 0.5s linear;
}
.popup__container {
  position: relative;
  align-self: center;
  margin: 0;
  padding: 34px 36px 28px 36px;
  display: flex;
  flex-direction: column;
  max-width: 430px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
}

.popup__title {
  margin: 0;
  padding: 0 0 22px 0;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.popup_opened {
  display: flex;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s linear;
}

.popup__link {
  color: #2f71e5;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
.button_type_close {
  border: none;
  position: absolute;
  top: -45px;
  right: -45px;
  width: 40px;
  height: 40px;
  align-self: flex-end;
  background: url('../../images/close.svg') no-repeat center;
}
@media screen and (max-width: 530px) {
  .popup__container {
    width: 100%;
  }
  .popup__button {
    width: 100%;
  }
  .button_type_close {
    border: none;
    position: absolute;
    top: -51px;
    right: 25px;
  }
  .popup__container {
    position: relative;
    align-self: flex-start;
    height: 100vh;
    margin: 56px 0 0 0;
    width: 90%;
  }
}
