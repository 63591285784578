.not-found {
  margin: 0;
  padding: 86px 0 80px 0;
  background: #f5f6f7;
  display: flex;
  justify-content: center;
}
.not-found__wrapper {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.not-found__icon {
  width: 74px;
  height: 74px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../images/not-found.svg');
}
.not-found__title {
  font-family: 'Roboto-slab', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.15;
  text-align: center;
  color: #1a1b22;
}

.not-found__text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: #b6bcbf;
}
