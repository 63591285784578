#root {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.page {
  margin: 0;
  padding: 0;
}
