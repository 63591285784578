.main-wrapper {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../images/main.png');
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main {
  margin: 80px auto;
}
.news__articles {
  margin: 0;
  padding: 62px 104px;
  background-color: #f5f6f7;
}
@media screen and (max-width: 920px) {
  .main {
    margin: 38px 40px;
  }
  .news__articles {
    padding: 62px 40px;
  }

  .news__articles {
    padding: 40px;
  }
}
@media screen and (max-width: 635px) {
  .main {
    margin: 30px 16px;
  }
  .news__articles {
    padding: 32px 16px 24px 16px;
  }
}
