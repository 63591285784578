.preloader {
  margin: 0;
  background: #f5f6f7;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preloader__circle {
  margin: 80px auto 24px auto;
  display: block;
  width: 74px;
  height: 74px;
  border: 4px solid #444;
  border-bottom-color: #888;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;
}
.preloader__text {
  top: 98px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #b6bcbf;
  margin-bottom: 80px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
