.footer {
  margin: 32px 106px 26px 104px;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.footer__copyright {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #b6bcbf;
}
.footer__links-wrapper {
  display: flex;
  justify-content: space-between;
}
.footer__links-text {
  display: flex;
  list-style: none;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: #1a1b22;
  padding: 0;
  margin: 0;
}
.footer__link-text {
  padding-left: 40px;
}
.footer__links-icons {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
}
.footer__link-icon {
  padding-left: 26px;
}
@media screen and (max-width: 920px) {
  .footer {
    margin: 24px 40px 18px 40px;
  }
}
@media screen and (max-width: 635px) {
  .footer {
    margin: 20px 16px 22px 16px;
    display: flex;
    flex-direction: column-reverse;
  }

  .footer__links-wrapper {
    display: flex;
  }
  .footer__links-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .footer__link-text {
    padding: 0 0 38px 0;
  }
  .footer__link-icon {
    padding-left: 28px;
  }
}
