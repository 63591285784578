.saved-news__wrapper {
  background-color: #ffffff;
  width: 100%;
  margin: 0;
  padding: 0;
}

.saved-news {
  display: flex;
  flex-direction: column;
  padding: 0 104px;
}
.saved-news__articles {
  margin: 0;
  padding: 62px 104px;
  background-color: #f5f6f7;
}
.saved-news__grid {
  max-width: 1232px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, calc((100% / 3) - 32px / 3));
  grid-template-rows: auto;
  grid-gap: 16px;
}

@media screen and (max-width: 920px) {
  .saved-news__articles {
    margin: 0;
    padding: 32px 40px;
  }
  .saved-news {
    padding: 0 40px;
  }
  .saved-news__grid {
    max-width: 1232px;
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, calc((100% / 3) - 16px / 3));
    grid-template-rows: auto;
    grid-gap: 8px;
  }
}
@media screen and (max-width: 635px) {
  .saved-news__grid {
    font-size: 30px;
    line-height: 1.13;
    margin: 0 0 58px 0;
  }
}
