.saved-news__header {
  margin: 0;
  padding: 40px 0 56px 0;
  display: flex;
  flex-direction: column;
}
.saved-news__articles-header {
  margin: 0 0 28px 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.33;
  color: rgba(26, 27, 34, 0.5);
}
.saved-news__message {
  margin: 0 0 30px 0;
  padding: 0;
  max-width: 530px;
  font-family: 'Roboto-slab', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 1.15;
  color: #1a1b22;
}
.saved-news__keywords-wrapper {
  display: flex;
}
.saved-news__keywords {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.33;
  color: #1a1b22;
}
.saved-news__keywords_bold {
  font-weight: bold;
}
@media screen and (max-width: 920px) {
  .saved-news__header {
    margin: 0;
    padding: 24px 0 32px 0;
  }
  .saved-news__articles-header {
    margin: 0 0 16px 0;
    font-size: 14px;
    line-height: 1.71;
  }
  .saved-news__message {
    max-width: 336px;
    margin: 0 0 16px 0;
    font-size: 30px;
    line-height: 1.13;
  }
  .saved-news__keywords {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.33;
    color: #1a1b22;
  }
  .saved-news__keywords_bold {
    font-weight: bold;
  }
}
