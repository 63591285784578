.search-form {
  display: flex;
  flex-direction: column;
}
.search-form__wrapper {
  display: flex;
}
.search-form__input {
  padding: 21px 24px;
  min-width: 608px;
  background: #ffffff;
  border: 1px solid #d1d2d6;
  backdrop-filter: blur(8px);
  border-radius: 100px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.38;
  color: #1a1b22;
}
.search-form__input::placeholder {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.38;
  color: #b6bcbf;
}
.search-form__button {
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.33;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  min-width: 168px;
  background: #2f71e5;
  box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
  border-radius: 100px;
  margin-left: -168px;
  z-index: 1;
  cursor: pointer;
}
.search-form__button:hover {
  background: #347eff;
}
.search-form__button:active {
  background: #2a65cc;
}
.search-form__error {
  padding: 0;
  margin: 10px 0 0 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ff0000;
  opacity: 0;
  transition: 0.3s;
}
.search-form__error_visible {
  opacity: 1;
}
@media screen and (max-width: 920px) {
  .search-form__input {
    padding: 17px 24px;
    min-width: 448px;
  }
  .search-form__button {
    min-width: 160px;
    margin-left: -160px;
  }
}
@media screen and (max-width: 635px) {
  .search-form__wrapper {
    display: flex;
    flex-direction: column;
  }
  .search-form__input {
    min-width: calc(100% - (32px / 2));
    padding: 17px 16px;
    margin-bottom: 16px;
  }
  .search-form__button {
    min-width: calc(100% - (32px / 2));
    padding: 17px 16px;
    margin: 0;
  }
}
